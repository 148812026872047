import React from "react"
import { Link, StaticQuery, graphql } from 'gatsby'
// import { Container, Row, Col } from 'react-grid-system'
import Modal from 'react-responsive-modal'
import "react-responsive-modal/styles.css";
import Img from "gatsby-image"
import styled from "styled-components"
import { ButtonStyles } from "./style"
import { Card as OldCard, Cards as OldCards }  from "./card"
import { Logo } from "./logo"

function Image({ data }) {
  return (
    data.image &&
    data.image.childImageSharp && (
      <ImageWrapper>
        <Img fluid={data.image.childImageSharp.fluid} />
      </ImageWrapper>
    )
  )
}

const Card = styled(OldCard)`
  border: 1px solid black;

  margin-bottom: 1.2rem !important;

  @media (min-width: ${props => props.theme.breakpoints.small}) {
    margin: 0rem !important;
  }
`

const Cards = styled(OldCards)`
  padding-top: 1rem;
`

const Title = styled.h2`
  font-size: 1.2em;
  color: ${props => props.theme.color.foreground};
  margin: 1rem 1rem .5rem 1rem;
  text-align: center;
`

const StyledA = styled.a`
  &:link {
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
`

const StyledLink = styled(Link)`
  &:link {
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
`

const ImageWrapper = styled.div`
  overflow: hidden;
`

const styles = {
  fontFamily: "sans-serif",
  textAlign: "center",
};

export class UnstyledModalShop extends React.Component {
  constructor(props) {
    super(props)
    this.state = { 
      open: false,
      modalImage: "",
      pathname: ""
    }
  }

  onOpenModal = (image) => {
    this.setState({ open: true, modalImage: image, pathname: window.location.pathname});
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  componentDidMount() {
    var that = this;
    setTimeout(function() {
      that.onOpenModal();
    }, 1300);
  }

  render() {
    const { open, pathname } = this.state;
    const sizes = "(max-width: 599px) calc(80vw - 2.5rem), (max-width: 1015px)  calc(40vw - 1.2rem - 1.2rem), 338px"

    // console.log(this.props);
    // console.log(this.props.data);
    // console.log(this.props.data.shop);
    // console.log(this.props.data.website);
    
    return (
      <div style={styles}>
        <Modal
          open={open} 
          onClose={this.onCloseModal}
          closeOnEsc={false}
          closeOnOverlayClick={false}
          blockScroll={true}
          showCloseIcon={false}
          focusTrapped={false}
          classNames={{
            modal: this.props.className,
          }}
        >
          {/* <p>
            Harm heeft nu ook een webwinkel geopend waar je zijn handgemaakte kerstboompjes kunt bestellen. Waar wil je naartoe?
          </p> */}
          
          <Logo />

          <Cards>
            <Card article key="shop">
              <StyledA href="https://www.shop.deklevenhorst.nl" target="_blank" rel="noopener" onClick={this.onCloseModal}>
                <ImageWrapper>
                  <Img fluid={{...this.props.data.shop.childImageSharp.fluid, sizes: sizes}} />
                </ImageWrapper>
                <Title>Ga naar de winkel</Title>
              </StyledA>
            </Card>
            <Card article key="website">
              <StyledLink onClick={this.onCloseModal} to={pathname}>
                <ImageWrapper>
                  <Img fluid={{...this.props.data.website.childImageSharp.fluid, sizes: sizes}} />
                </ImageWrapper>
                <Title>Blijf op de website</Title>
              </StyledLink>
            </Card>
          </Cards>
          <ModalContent>
            De handgemaakte kerstboompjes en vogelhuisjes van Harm zijn te bestellen via zijn online winkel. Informatie over De Klevenhorst en reserveringen kun je vinden op onze website. Wil je naar de winkel gaan of op de website blijven?
          </ModalContent>
        </Modal>
      </div>
    );
  }
}

const ModalContent = styled.p`
  padding-top: 1rem;
`

export const ModalShop = styled(UnstyledModalShop)`
  max-width: 80vw;
  width: 80vw;
`

const ModalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80vw;
  max-width: calc(800px - 2.4rem);
`

const ModalBlock = styled.div`
  display: block;
  float: left;
  width: calc(50% - 0.6rem);
`

export default props => (
  <StaticQuery
    query={graphql`
      query myquery {
        shop: file(relativePath: { eq: "images/IMG_0829.png" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          relativePath
        }
        website: file(relativePath: { eq: "images/160528WH053.jpg" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          relativePath
        }
      }
    `}
    render={data => (
      <ModalShop data={data} {...props} />
    )}
  />
)


// export const Logo = () => {
//   const data = useStaticQuery(graphql`
//     query {
//       file(relativePath: { eq: "images/logo.png" }) {
//         childImageSharp {
//           fluid(quality: 70, maxWidth: 110) {
//             ...GatsbyImageSharpFluid_withWebp
//           }
//         }
//         relativePath
//       }
//     }
//   `)
//   const sizes = "(max-width: 599px): 110px, 140px"
//   return (
//     <ImageWrapper>
//       <Img fluid={{...data.file.childImageSharp.fluid, sizes: sizes}} />
//     </ImageWrapper>
//   )
// }


// export ModalShop;

// export default props => (
//   <ModalShop {...props} />
// )








// import React from "react";
// import { render } from "react-dom";
// import "react-responsive-modal/styles.css";
// import { Modal } from "react-responsive-modal";

// const styles = {
//   fontFamily: "sans-serif",
//   textAlign: "center"
// };

// class App extends React.Component {
//   state = {
//     open: false
//   };

//   onOpenModal = () => {
//     this.setState({ open: true });
//   };

//   onCloseModal = () => {
//     this.setState({ open: false });
//   };

//   render() {
//     const { open } = this.state;
//     return (
//       <div style={styles}>
//         <h2>react-responsive-modal</h2>
//         <button onClick={this.onOpenModal}>Open modal</button>
//         <Modal open={open} onClose={this.onCloseModal}>
//           <h2>Simple centered modal</h2>
//           <p>
//             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
//             pulvinar risus non risus hendrerit venenatis. Pellentesque sit amet
//             hendrerit risus, sed porttitor quam.
//           </p>
//         </Modal>
//       </div>
//     );
//   }
// }

// render(<App />, document.getElementById("root"));
